import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const { io } = require("socket.io-client");
export default function Booking() {
    const [activeSlot, setActiveSlot] = useState(null);
    const [slot, setSlot] = useState({});
    const [data, setData] = useState([]);
    const [socket, setSocket] = useState(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");
    const [gander, setGander] = useState("");
    const [address, setAddress] = useState("");
    const [cancelSlote, setCancelSlote] = useState(false);
    const [cdata, setCdata] = useState([]);
    const bookFunction = () => {

    }

    // console.log('date=====================>', new Date('2023-04-16T18:30:14.845Z'), new Date('2023-04-16T18:30:14.845Z').getDate());
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setActiveSlot(null);
        setCdata(null);
    }
    const handleShow = () => setShow(true);

    const [cshow, setCshow] = useState(false);

    const handleCClose = () => setCshow(false);
    const handleCShow = () => setCshow(true);

    const ganderFun = (item) => {
        setGander(item);
    }

    const setSloteFunc = (item) => {
        setActiveSlot(item)
        handleShow();
    }

    const bookingCancleFun = () => {
        handleCShow();
    }

    const baseURL = "https://api.chirayuchildcareclinic.com/";
    // const baseURL = "https://192.168.1.49:8085/";

    useEffect(() => {
        // getSlot();
        const socket = io(baseURL + "booking", { transports: ["polling"], upgrade: false });

        socket.on("connect", (socket2) => {
            console.log('connect socket');
            socket.emit("slot");
        });
        socket.on("getslot", (res) => {
            console.log('slote list', res);
            if (res?.statusCode == 200) {
                setData(res?.data)
            } else {
                setData([]);
            }
            // socket.emit("list", { id: leadId, auth: user?.token });
        });

        socket.on("booked", (res) => {
            // console.log('book socket', res);
            let toastId = 'update';
            // getSlot();
            if (res?.statusCode == 200) {
                setActiveSlot(null);
                setName("");
                setPhone("");
                setShow(false);
                if (!toast.isActive(toastId)) {
                    toastId = toast.success('Booking Confirmed', {
                        toastId: toastId
                    });
                }
            } else if (res?.statusCode == 208) {
                setActiveSlot(null);
                if (!toast.isActive(toastId)) {
                    toastId = toast.error(res?.error, {
                        toastId: toastId
                    });
                }
            } else {
                setActiveSlot(null);
                if (!toast.isActive(toastId)) {
                    toastId = toast.error('Somthing Went Wrong', {
                        toastId: toastId
                    });
                }
            }

        });

        socket.on('cancelled', (res) => {
            if (res?.statusCode == 200) {
                toast.success("Booking cancellation is cancelled!");
                setCshow(false);
                setCancelSlote(false);
                // getSlot();
            } else {
                toast.error(res?.error);
            }
        })

        setSocket(socket);
    }, []);

    const cancelsendMsg = () => {

        socket.emit("cancel", {
            mobile: phone,
        });

    }

    const checkFun = () => {

        fetch(baseURL + 'event/get_booking',
            {
                method: 'POST',
                headers: {
                    // mode: 'no-cors',
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: phone })
            }
        )
            .then(response => response.json())
            .then(json => {

                if (json?.statusCode == 200) {
                    setCdata(json?.data);
                    setCancelSlote(true);
                } else {
                    toast.error(json?.error);
                }
            })
    }
    const sendMsg = (values) => {
        setShow(false);

        console.log('emiting booking add', gander);
        if (activeSlot) {
            if (name !== "") {
                if (phone.length == 10) {

                    if (address !== "") {
                        if (gander !== "") {
                            socket.emit("add", {
                                msg: values?.message,
                                slotId: activeSlot?._id,
                                mobile: phone,
                                name: name,
                                gender: gander,
                                address: address
                            });
                        } else {
                            toast("Select Your Gander please!");
                        }
                    } else {

                        toast("Enter your Address please!");
                    }

                } else {
                    toast("Invalid phone Number. Please try again.");

                }
            } else {
                toast("Enter your Name please!");
            }

        } else {
            toast("Select a slot Please!");
        }
        // getSlot();
    }

    const handleValidate = (phoneNumber) => {


    }

    // console.log('datadatadata', data);

    return (
        <div class="c-us-area" id='booking_b'>
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 offset-xl-2">
                        <div class="site-title text-center">
                            <h2>Make an Appointment </h2>
                            <p>Booking for next day will start 09:30 PM daily.</p>
                        </div>
                    </div>
                </div>
                <div class="c-us-wpr">
                    <div class="row">
                        {data.isActive &&
                            <div className='col-12 order_2_phone'>
                                <Button className='booking_cancel' onClick={bookingCancleFun}>Booking Cancel</Button>
                            </div>
                        }
                        <div class="col-xl-12">
                            {
                                data.isActive && data?.slot.length < 1 ? <p>Online appointments for today are completly booked please contact 9811029499 for any assistance.</p> : null
                            }
                            {data.isActive ?
                                <div class="row form-row">
                                    <div className='col-12'>
                                        <div className='row slote_box'>
                                            {data?.slot?.map((item, index) => {
                                                // console.log('slote length',item)
                                                return (
                                                    <>
                                                        {activeSlot?._id == item?._id ?
                                                            <div className='col-md-3 col-sm-4 col-6' key={index}>
                                                                <button type='button' className="feature-box d-flex align-items-center active">
                                                                    <div className="feature-info">

                                                                        <p>{item?.startAt}-{item?.endAt}</p>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className='col-md-3 col-sm-4 col-6'>
                                                                    <button type='button' className="feature-box d-flex align-items-center " onClick={() => setSloteFunc(item)}>
                                                                        <div className="feature-info">
                                                                            <p className='booking-open'>{item?.startAt}-{item?.endAt}</p>

                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <Modal show={show} className="appoinment_popup" centered onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>REQUEST AN APPOINTMENT</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form>
                                                <div className='row form_box_app'>
                                                    <div class="form-group col-md-6">
                                                        <label for="inputAddress">Name</label>
                                                        <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} required />
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label for="inputCity">Phone Number</label>
                                                        <input type="text" class="form-control" maxLength={10} onChange={(e) => setPhone(e.target.value)} required />
                                                        <div id="emailHelp" class="form-text">{error}</div>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label for="inputAddress">Address</label>
                                                        <input type="text" class="form-control" onChange={(e) => setAddress(e.target.value)} required />
                                                    </div>
                                                    <div class="form-group check-box-section-box col-md-6">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" onClick={() => ganderFun('Male')} name="flexRadioDefault" id="male" />
                                                            <label class="form-check-label" for="male">
                                                                Male
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" onClick={() => ganderFun('Female')} name="flexRadioDefault" id="female" />
                                                            <label class="form-check-label" for="female">
                                                                Female
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" onClick={() => ganderFun('Other')} name="flexRadioDefault" id="female" />
                                                            <label class="form-check-label" for="female">
                                                                Other
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        <button type="button" onClick={sendMsg} class="bt">Appointment</button>
                                                    </div>
                                                </div>


                                            </form>
                                        </Modal.Body>

                                    </Modal>

                                    {/** Booking Cancel popup */}

                                    <Modal show={cshow} className="appoinment_popup" centered onHide={handleCClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>CANCEL AN APPOINTMENT</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form>
                                                <div className='row form_box_app'>
                                                    {
                                                        cancelSlote == false ?

                                                            <div class="form-group col-md-12">
                                                                <label for="inputCity">Phone Number</label>
                                                                <input type="text" class="form-control" maxLength={10} onChange={(e) => setPhone(e.target.value)} required />
                                                                <div id="emailHelp" class="form-text">{error}</div>
                                                            </div>
                                                            :
                                                            <div className='personDetails'>
                                                                <p><strong>Name</strong> {cdata[0]?.name}</p>
                                                                <p><strong>Mobile</strong> {cdata[0]?.mobile}</p>
                                                                <p><strong>Gander</strong> {cdata[0]?.gender}</p>
                                                                <p><strong>Address</strong> {cdata[0]?.address}</p>
                                                                <p><strong>Time Slot</strong> {cdata[0]?.slot[0].startAt} - {cdata[0]?.slot[0].endAt}</p>

                                                            </div>
                                                    }
                                                    <div className='col-12 text-center'>
                                                        {
                                                            cancelSlote == false ? <button type="button" onClick={checkFun} class="bt">Check</button> : <button type="button" onClick={cancelsendMsg} class="bt">Cancel Appointment</button>
                                                        }
                                                    </div>
                                                </div>


                                            </form>
                                        </Modal.Body>

                                    </Modal>

                                </div>
                                :
                                <p>{data?.desc}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


